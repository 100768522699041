import { ref } from 'vue'
import { services } from "@/plugins/installServices";

export default class FileList {
  uploadableFiles = ref<UploadableFile[]>([])
  uploading = ref(false)

  clear = () => {
    this.uploadableFiles.value = [];
    this.uploading.value = false;
  }

  addFiles = (newFiles: File[]) => {
    if (this.uploading.value) return
    var newUploadableFiles: UploadableFile[] = [];
    if (newFiles instanceof File) {
      newUploadableFiles = [new UploadableFile(newFiles)]
    } else if (newFiles instanceof Array && newFiles.every(item => item instanceof File)) {
      newUploadableFiles = newFiles
        .map((file) => new UploadableFile(file))
        .filter((file) => !this.fileExists(file.id))
    }
    this.uploadableFiles.value = this.uploadableFiles.value.concat(newUploadableFiles)
  }

  fileExists = (otherId: string) => {
    return this.uploadableFiles.value.some(({ id }) => id === otherId)
  }

  removeFile(file: UploadableFile) {
    if (this.uploading.value) return
    const index = this.uploadableFiles.value.indexOf(file)
    if (index > -1) this.uploadableFiles.value.splice(index, 1)
  }

  public async batchUploadFiles() {
    this.uploading.value = true;
    const batchSize = 5;

    const uploadFile = async (file: UploadableFile): Promise<void> => {
      file.status = "";
      let tusUpload: UseTusUploadReturn = await useTusUpload({
        endpoint: '/api/upload',
        apiKey: services.authorizationService().apiKey()!!,
        onSuccess: (url) => {
          file.status = "Upload completed";
          this.removeFile(file)
        },
        onError: (err) => {
        },
        retryDelays: [500]
      });
      try {
        return await tusUpload.upload(file.file)
      } catch (error: any) {
        if (error.originalResponse.getStatus() == 409) {
          file.status = "Already exists! " + file.id;
        } else {
          file.status = "Failed with error:" + file.id + " -> " + error.originalResponse.getBody()
        }
      }
      return
    };

    // Split the array into batches
    const fileBatches: UploadableFile[][] = [];
    for (let i = 0; i < this.uploadableFiles.value.length; i += batchSize) {
      let batch = this.uploadableFiles.value.slice(i, i + batchSize).filter((file) => file.status != 'success');
      fileBatches.push(batch);
    }

    // Process each batch sequentially
    for (const batch of fileBatches) {
      let task: Promise<void>[] = batch.map(uploadFile);
      // Use Promise.all to process files in parallel within the batch
      await Promise.all(task);
    }
    this.uploading.value = false;
  }
}

export class UploadableFile {
  file: File
  id: string
  url: string
  status: string | null

  constructor(file: File) {
    this.file = file
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    this.url = URL.createObjectURL(file)
    this.status = null
  }
}
export const createDownloadableFile = (
  data: string,
  fileName: string,
  contentType: string
): void => {
  // Create a Blob from the data
  const blob = new Blob([data], { type: contentType })

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob)

  // Create a hidden anchor link
  const element = document.createElement('a')
  element.style.display = 'none'

  // Set the download URL
  element.href = url
  element.setAttribute('download', fileName)

  // Append to DOM and simulate click
  document.body.appendChild(element)
  element.click()

  // Cleanup
  document.body.removeChild(element)
  window.URL.revokeObjectURL(url) // Important: revoke the URL to free memory
}
