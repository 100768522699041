import ItemType from "./ItemType";

export class Item implements FilterableItem {
  itemState: string = '';
  name: string = '';
  description: string = '';
  price: number = 0;
  number: number = 0;
  itemType: ItemType = ItemType.UNKNOWN;
  priceTogo: number = 0;
  category: Category = { name: 'UKNOWN' };
  numberOfItems: number = 0;
  image: string = '';
  validCustomerTypeNumbers: number[] = [];
  overViewImageZoom: number = 0;
  detailViewImageZoom: number = 0;
  characteristics: string[] = [];
  selectedCombinationItems: Item[] = [];
  combinationItems: CombinationItem[] = [];
  // 0 is infinite
  maximumCombinationItems: number = 0;

  public combinationNumber() {
    return Item.toCombinationItemnumber(this.number, this.selectedCombinationItems)
  };

  public static toCombinationItemnumber(mainItemNumber: number, selectedCombinationItems: Item[]) {
    if (selectedCombinationItems && selectedCombinationItems.length > 0) {
      return mainItemNumber + "_" + selectedCombinationItems.filter(item => item.numberOfItems > 0).map(item => item.number + "*" + item.numberOfItems).join("_")
    } else {
      return ""
    }
  }

  public displayCombination() {
    if (!this.selectedCombinationItems == null || this.selectedCombinationItems.length == 0) {
      return ""
    }
    return this.selectedCombinationItems.map(combItem => combItem.name + " x " + combItem.numberOfItems).join(", ");
  }

  public cloneFrom(item: Item) {
    return Object.assign(this, item);
  }
}

/**
 * Used to configure the items that can be in combination with the item
 * and the maximum number allowed. 0 is infinite. 
 */
export class CombinationItem implements FilterableItem {
  number: number = 0;
  name: string = "";
  maximumNumberOfItems: number | undefined;
}

export interface Category {
  name: string;
}

export const allCharacteristics = [
  "VEGETARIAN",
  "VEGAN",
  "ALLERGEN_GLUTEN",
  "ALLERGEN_LUPIN",
  "ALLERGEN_CELERY",
  "ALLERGEN_CRUSTACEANS",
  "ALLERGEN_MILK",
  "ALLERGEN_SULPHUR_DIOXIDE",
  "ALLERGEN_SESAME",
  "ALLERGEN_MOLLUSCS",
  "ALLERGEN_MUSTARD",
  "ALLERGEN_TREE_NUTS",
  "ALLERGEN_EGG",
  "ALLERGEN_FISH",
  "ALLERGEN_SOYBEANS",
  "ALLERGEN_PEANUTS"
]

export interface FilterableItem {
  name: string,
  number: number
}

export const sortCharacteristics = (characteristics: string[]) => characteristics.sort((characteristic1, characteristic2) => {
  let index1 = allCharacteristics.indexOf(characteristic1);
  let index2 = allCharacteristics.indexOf(characteristic2);
  if (index1 < index2) {
    return -1;
  } else if (index1 > index2) {
    return 1;
  }
  return 0;
});

const maskRulePart = (v: string) => {
  if (v.startsWith("#")) {
    let parts = v.substring(1).split("-")
    if (parts.length > 2) {
      return false
    }
    // If any part isn't a number, return false
    for (let part of parts) {
      if ('' !== part.trim() && isNaN(parseInt(part))) {
        return false
      }
    }
    // if there are two parts, the first must be smaller than the second
    if (parts.length == 2 && parts[0] != "" && parts[1] != "") {
      return parseInt(parts[0]) <= parseInt(parts[1])
    }
    return true
  }
  return true
}

export const maskRule = (mask: string) => {
  let parts = mask.split(",");
  for (let part of parts) {
    if (!maskRulePart(part)) {
      return false
    }
  }
  return true
}

export const filter = (filter: string, items: FilterableItem[]): any[] => {
  let parts = filter.split(",");
  let filteredItems: FilterableItem[][] = [];
  for (let part of parts) {
    filteredItems.push(filterItems(part, items))
  }
  // merge the filtered items in the arrays to one and remove duplicates
  return filteredItems.flat().filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.number === item.number
    ))
  )
}

const filterItems = (filter: string, items: FilterableItem[]) => {
  let start: number | undefined = undefined;
  let end: number | undefined = undefined;
  if (filter.startsWith("#") && maskRule(filter)) {
    if (!filter.includes("-")) {
      start = parseInt(filter.substring(1))
      end = start
    } else {
      let parts = filter.substring(1).split("-")
      if (parts.length == 1) {
        start = parseInt(parts[0])
      } else {
        start = parseInt(parts[0])
        end = parseInt(parts[1])
      }
    }
  }

  return items.filter((item) => {
    if (start || end) {
      let itemNumber = item.number
      if (isNaN(itemNumber)) return false;
      if (start && end) {
        return itemNumber >= start && itemNumber <= end
      } else if (start) {
        return itemNumber >= start
      } else if (end) {
        return itemNumber <= end
      }
    } else {
      return item.name && item.name.toLowerCase().includes(filter.toLowerCase())
    }
  })
}




